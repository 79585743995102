
import './BusinessCard.less';
import osLogoImage from "../../assets/images/MO_Logo.png";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function CopyToClipBoard() {
  

}

function BusinessCard() {
  var state = {
    value: '',
    copied: false,
  };
  

  return (

    <div>
        <div id="business_card" >
          <ul>
          {/* <h4>Michael Otterbine</h4><img src={osLogoImage} alt="OS Logo"/> */}
            <h4>Michael&nbsp;Otterbine</h4>
            <h5>Solutions&nbsp;Engineer</h5>
            <li><span>908-770-1405</span></li>
            <li><span>Mike@OtterbineSolutions.com</span></li>
             {/*<li><a href='https://www.linkedin.com/in/michael-otterbine-11091928/' title="Opens Mike Otterbine's LinkedIn Page in a new window." target="_blank" rel="noopener noreferrer">LinkedIn</a>
            <a href="\MikeOtterbineResume" title="Mike Otterbine's Resume" target="_self" rel="noopener noreferrer" className='justifyRight'>CV/Resume</a> </li>*/}
          </ul>
        </div>
  
        {/* <CopyToClipboard text={this.state.value}
          onCopy={() => this.setState({copied: true})}>
          <span>Copy to clipboard with span</span>
        </CopyToClipboard> */}
  
    </div>



  );
}


  export default BusinessCard