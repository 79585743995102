
function NotFound() {
    return (
      <div className="NotFound">
        <header className="app-header">
        <h3>Page Not Found</h3>
      <h5>♪♫♫</h5>
      <h5>We couldn't figure out where you wanted to go...</h5>
          <a className="App-link" rel="noopener noreferrer"
            href="\">  {/* // target="_blank" */}
           
            <h3>Home</h3>
          </a>
       
        </header>
      </div>
    );
  }


  export default NotFound