import './App.less';
import ReactIntro from './Components/ReactIntro'
//import { Header, Footer, NotFound }  from './Components';
import Header  from './Components/Header/Header'
import HashingExample from './Components/HashingExample/HashingExample'
import Footer  from './Components/Footer/Footer'
import Home from './Components/Home'
import NotFound from './Components/NotFound'
import Resume from './Components/MOResume/MOResume'
import OBDIIAppPrivacyPolicy from './Components/PrivacyPolicies/OBDIIAppPrivacyPolicy'
import OBD2AppPrivacyPolicy from './Components/PrivacyPolicies/OBD2AppPrivacyPolicy'
import NorthFinderAppPrivacyPolicy from './Components/PrivacyPolicies/NorthFinderAppPrivacyPolicy'
import MoneyTimesAppPrivacyPolicy from './Components/PrivacyPolicies/MoneyTimesAppPrivacyPolicy'
import BucketBallAppPrivacyPolicy from './Components/PrivacyPolicies/BucketBallAppPrivacyPolicy'
import MoonDefenderAppPrivacyPolicy from './Components/PrivacyPolicies/MoonDefenderAppPrivacyPolicy'

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom"

import React, { useLayoutEffect, useState, useEffect } from 'react';

import $ from 'jquery';
window.$ = $;

const SetupScrollListener = (props) => {
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      //let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      let currentPosition = window.scrollY; // or use document.documentElement.scrollTop;
      if (currentPosition > scrollTop) {
        // downscroll code
        $("#sidebar-wrapper").removeClass("active");
        setScrolling(false);
      } else {
        // upscroll code
        $("#sidebar-wrapper").removeClass("active");
        setScrolling(true);
      }
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);
}

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);

      $("#sidebar-wrapper").removeClass("active");
       
      let hdr = $("#header");
      let ft = $('footer');
  
      let body = $('#bodySection');  
       
      body.css("padding-top", hdr.height());// = 100;//hdr.height();
      var fHt = ft.height() 

      body.css("padding-bottom", ft.height()+ 100);// = 100;//hdr.height();

    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function App() {
  const [width, height] = useWindowSize();
  var j = SetupScrollListener();
    return (

    <div>

      <Header>
        {/* <h2 style={{marginBottom:'.5em', textAlign:'center'}}>Welcome to Mike&nbsp;Otterbine's Home Page</h2>
          <h5>(Otterbine Solutions)</h5> */}
        
      </Header>
      <BrowserRouter>
      <div id="bodySection"> 
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="OSNFPrivacyPolicy" element={<NorthFinderAppPrivacyPolicy />} />
          <Route path="OBD2AppPrivacyPolicy" element={<OBD2AppPrivacyPolicy />} />
          <Route path="OBDIIAppPrivacyPolicy" element={<OBDIIAppPrivacyPolicy />} />
          <Route path="OSICAppPrivacyPolicy" element={<MoneyTimesAppPrivacyPolicy />} />
          <Route path="BucketBallAppPrivacyPolicy" element={<BucketBallAppPrivacyPolicy />} />
          <Route path="MoonDefenderAppPrivacyPolicy" element={<MoonDefenderAppPrivacyPolicy />} />
          <Route path="MikeOtterbineResume" element={<Resume />} />
          <Route path="HashingExample" element={<HashingExample />} />
          <Route path="contact">
              <Route index element={<Home />} />
              <Route path=":id" element={<ReactIntro />} />
          </Route>
          <Route path="*" element={<NotFound />} />
          {/* </Route> */}
        </Routes>
      </div>
      </BrowserRouter>
      <Footer></Footer>
    </div>
  );
}

export default App;
