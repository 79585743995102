import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './Header.less';
import App from '../../App';
import headerLogoSource from "../../assets/images/WOI-logo.png"
import $ from 'jquery';
window.$ = $;

var bannerTitle = "";
var ToggleNavBar = ()=>{$("#sidebar-wrapper").toggleClass("active");}
var CloseNavBar = ()=>{$("#sidebar-wrapper").removeClass("active");}

function Header(){

  return  <div className="noprint" style={{margin: 0, padding:0}}>
  
  <div id="header" className="header" style={{zIndex: 99}}>
    <div className="header-boxes-container">
      <div className="box">
        <a href="/" title="Wake of Intention Studios Home Page">
          <div className="page-logo">
            <img src={headerLogoSource} alt="WOI Logo"/>
          </div>
        </a>
      </div>
  
      <div className="boxm d-xm-block">
        <div className="banner-text-wrapper">
          <div id="banner-block" className="banner-block fade-in-long">
            <h4 id="fading-words-display">{bannerTitle}</h4>
          </div>
        </div>
      </div>
  
  
      <div className="right-menu-box ">
        <div className="navbar-toggle" onClick= {ToggleNavBar}>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
          <span className="icon-bar"></span>
        </div>
      </div>
  
  
  
    </div>
    <div>
  
    </div>
  
    <div id="sidebar-wrapper" className='' >
      {/* <h5>Links</h5> */}
      <nav>
        <a href="\Home" title="Go to home page" onClick={CloseNavBar}>Home</a>
        {/* <a href="https://play.google.com/store/apps/dev?id=6713663433526193446" title="Mike Otterbine, Google Play Developer" target="_blank" onClick={CloseNavBar}>On Google Play</a>
        <a href="https://www.thoughtpill.com/odb2mobiledownload" title="Download page for OBD2 Applications" target="_blank" onClick={CloseNavBar}>OBD2 Applications</a>
        <a href="https://www.thoughtpill.com/vinlookup" title="Lookup a car VIN" target="_blank" onClick={CloseNavBar}>VIN Lookup</a> */}
        {/* <a href="https://github.com/MOtterbine" title="Mike's GIThub page" target="_blank" onClick={CloseNavBar}>Mike's Github page</a>
        <a href="\HashingExample" title="An example of hash functions" onClick={CloseNavBar}>Hashing Example</a>
        <a href="\MikeOtterbineResume" title="View Mike's resume" onClick={CloseNavBar}>CV</a>  */}
      </nav>
      <p style={{position: "absolute", bottom: "0px",right:0,left:0, lineHeight: '1.5em',textAlign: "center",cursor: "pointer"}} onClick={CloseNavBar}>Close</p>
    </div>
  
  </div>
  </div>
  
  
  
  
}

export default Header

