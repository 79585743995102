import './MainStyles.less';
import BusinessCard from './BusinessCard/BusinessCard';
import Timer from './Timer/Timer';

function Home() {
    return (
      <div className="Home">
        <header className="app-header">
          <div className='title'>
            <h2 style={{marginBottom:'.5em', textAlign:'center'}}>Wake of Intention Studios</h2>
            <h5>(where art and science play)</h5>
            {/* <Timer/> */}
          </div>
        </header>
        <div className="business-card">
          <BusinessCard></BusinessCard>
        </div>
      </div>

    );




  }


  export default Home