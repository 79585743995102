import React from 'react';
import './Footer.less';

var copyRightText = "Wake of Intention " + new Date().getFullYear();
var address1 = "Wake of Intention"
var address2 = "241 Edgeview Road"
var address3 = "Keyport, NJ 07735"
function Footer(){
  //  return <div className="footer-class"><h1>Whammy Jammeny</h1></div>


    return <footer className="footerbackground noprint">
    <div id="footerarea" className="footertext" >
    <div style={{display:'inline-block',padding:'10px 0 10px 0'}}>
      <p className='leftp'>{address1}</p>
      <p className='leftp'>{address2}</p>
      <p className='leftp'>{address3}</p>
    </div>
    <div style={{display:'inline-block', float:"right", verticalAlign:'center'}}>
      <p>{copyRightText}</p>
    </div>
    </div>
  </footer >
  
}

export default Footer


