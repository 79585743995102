import React from 'react';
import './HashingExample.less';

var encodedText = "Jammin on the one...";
var textToEncode = "Jammin on the two...";




function HashingExample(){

  var useKey = false;
function onChangeUseKey(event) {
  //console.log("Changed..." + event.target.checked);
  console.log("Changed..." + useKey);
} 

return (
  <div class="HashingExample">
      <div class="container22" style={{height:'auto', minHeight:'400px',paddingBottom:'25px'}}>
    <h3 class="text-center22">Data Hashing Example</h3>
  

    <div class="card22 text-left22" style={{display:'normal',marginTop:'30px'}}>
      <div class="card-body22">

  
      <div>shit</div>
      <div>shit</div>
      <div>shit</div>
      <div>shit</div>
      <div>shit</div>
      <div>shit</div>

        <h5 class="card-title" style={{display:'inline-block'}}>Encoding Plain Text</h5>
        <h6 class="card-subtitle mb-2 text-muted">text to encode</h6>

        
        <p id="scripts-text" class="card-text" style={{display:'flex',flexFlow:'row'}}>
          <textarea placeholder="Any text you want to encode..." rows="3" wrap="off" style={{width:"100%", height:"auto", resize: "none", textarea:{textToEncode}}}></textarea>
        </p>
  
  
  
  
    
  
        <input onChange={onChangeUseKey} value={useKey} type="checkbox" title="Use a key" style={{margin:'0 5px 0 0', padding:'0'}} />
 {/* 
 

        <p id="scripts-text" class="card-text" style="display: flex;flex-flow:row;">
          <textarea placeholder="Your Own Personal Key" rows="1" wrap="on" style="width: 100%;height:auto;resize: none;" [(ngModel)]="keyToEncode"></textarea>
        </p>
  
        <select (change)="onChangeAlgorithm($event)"  [(ngModel)]="selectedEncodeType"   style="margin-right: 10px;vertical-align: middle;">
          <option *ngFor="let algoritm of encodeTypes" [ngValue]="algoritm.name">{{algoritm.name}}</option>
        </select><br>
        <input type="button" value="Encode" class="btn btn-primary btn-default" (click)="Encode()">
        <input type="button" value="Clear" class="btn btn-primary btn-default" (click)="Clear()">
  
        <h6 class="card-subtitle mb-2 mt-3 text-muted">output</h6>
        <p id="scripts-text" class="card-text" style="display: flex;flex-flow:row;">
          <textarea rows="4" wrap="on" style="width: 100%;height:auto;resize: none;" disabled>{encodedText}</textarea>
        </p>
*/}
      </div>
   </div> 
     </div>
   </div>  
);
}

export default HashingExample


