
import './MOResume.less';

import $ from 'jquery';
window.$ = $;


function MOResume() {

  $("#fading-words-display").text("");

  $(document).prop("title", "Mike Otterbine | Resume");
      
  return (

    <div className='MOResume'>

        <div className="container" id="document-page1">
          
        
          <div className="row justify-content-center" style={{paddingTop:'1em'}}>
            <div className="col-md-8" style={{textAlign:'left'}}>
              <table >
                <tbody>
                <tr style={{paddding:'0'}}>
                  <td><h4 ><strong>Mike Otterbine</strong></h4></td>
                </tr>
                <tr>
                  <td><strong>Solutions Engineer</strong></td>
                  {/* <td style={{fontWeight:'600'}}>Solutions Engineer</td> */}
                </tr>
                
                  <tr className="d-block d-md-none mt-2">
                    <td>241 Edgeview Road</td>
                  </tr>
                  <tr className="d-block d-md-none">
                    <td>Keyport, NJ 07735</td>
                  </tr>
                  <tr className="d-block d-md-none">
                    <td>MikeOtterbine@Gmail.com</td>
                  </tr>
                  <tr className="d-block d-md-none mb-2">
                    <td>(908) 770-1405</td>
                  </tr>
               
              <tr>
                  <td className="d-block d-md-none"><a target="_blank" rel="noopener noreferrer" href="https://github.com/MOtterbine">My Github Repo</a></td>
                  <td className="d-none d-md-block"><a target="_blank" rel="noopener noreferrer" href="https://github.com/MOtterbine">https://github.com/MOtterbine</a></td>
                </tr>
                <tr>
                  <td className="d-block d-md-none"><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/michael-otterbine-11091928/">My LinkedIn Page</a></td>
                  <td className="d-none d-md-block"><a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/michael-otterbine-11091928/">https://www.linkedin.com/in/michael-otterbine-11091928/</a></td>
                </tr>
                <tr>
                  <td className="d-block d-md-none"><a target="_blank" rel="noopener noreferrer" href="https://www.otterbinesolutions.com">My Personal Web Page</a></td>
                  <td className="d-none d-md-block"><a target="_blank" rel="noopener noreferrer" href="https://www.otterbinesolutions.com">https://www.otterbinesolutions.com</a></td>
                </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-4" >
              <table className="right-column d-none d-md-block" style={{textAlign: 'left'}}>
                <tbody>
                <tr>
                  <td>241 Edgeview Road</td>
                </tr>
                <tr>
                  <td>Keyport, NJ 07735</td>
                </tr>
                <tr>
                  <td>MikeOtterbine@Gmail.com</td>
                </tr>
                <tr>
                  <td>(908) 770-1405</td>
                </tr>
                </tbody>
            </table>
            </div>
          </div>
        
          <div className="row justify-content-center" style={{marginTop:'1.2em'}}>
            <div className="col-12">
              <h4>Related Websites</h4>
              <ul style={{listStyle:"none", listStyleType:"none"}} className="d-block d-md-none">
                <li><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/dev?id=6713663433526193446">My Google Play Dev Page</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.thoughtpill.com/odb2mobiledownload">OBDII Android App Page</a></li>
                <li><a target="_blank" rel="noopener noreferrer" href="https://www.thoughtpill.com/odb2appdownload">OBDII WIndows App Page</a></li>
              </ul>
              <ul style={{listStyle:"none", listStyleType:"none"}} className="d-none d-md-block">
                <li>My Google Play Dev Page: <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/dev?id=6713663433526193446">https://play.google.com/store/apps/dev?id=6713663433526193446</a></li>
                <li>OBDII Android Application Home Page: <a target="_blank" rel="noopener noreferrer" href="https://www.thoughtpill.com/odb2mobiledownload">https://www.thoughtpill.com/odb2mobiledownload</a></li>
                <li>OBDII WIndows Application Home Page: <a target="_blank" rel="noopener noreferrer" href="https://www.thoughtpill.com/odb2appdownload">https://www.thoughtpill.com/odb2mobiledownload</a></li>
              </ul>
            </div>
          </div>
        
        
        
          <div className="row justify-content-center" style={{marginTop:'1em'}}>
            <div className="col-12">
              <h4>Qualifications</h4>
              <ul style={{listStyle:"none", listStyleType:"none"}} >
                <li >Over 15 years experience in electronics, windows and web application design. c#, asp.net/mvc, WPF, Xamarin, MAUI, Sockets, multithreading/tasks, UI/UX, Web, Javascript, REST, node, Angular, React, CSS/LESS, Bootstrap</li>
              </ul>
            </div>
          </div>
        
          <div className="row justify-content-center">
            <div className="col-12">
              <h4 className="d-none d-md-block" style={{margin:'1.0em 0 1em 0'}}>Professional Experience</h4>
              <h4 className="d-block d-md-none" style={{paddingTop:'.75em',textAlign: 'center',margin:'1.0em 0 2em 0',borderTtop:'1px solid grey'}}><strong>Professional Experience</strong></h4>
            </div>
          </div>
        
        
          <div className="row justify-content-center">
            <div className="col-12">
            <h4>Consultant <span className="time-span">(9/21-present)</span></h4>
            {/* <h4>OS OBDII Interface (Google Play App)<span className="time-span">(11/20-present)</span></h4> */}
              <h5><strong>Product Owner: OS OBDII Interface (Google Play App)<span className="time-span">  (11/20-present)</span></strong></h5>
              <ul>
                <li>Execute design, development, maintenance and publishing
                  <ul className="d-block d-md-none">
                    <li>User-Friendly and refined WPF-based UI/UX with multi-threaded real-time communications</li>
                    <li>VS2022, Android 12 with hardware integration (WiFi and Bluetooth)</li>
                    <li>MVVM with Xaml, Xamarin and /Maui</li>
                    <li><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.OtterbineSolutions.os_obd2">OS OBDII Interface on Google Play</a></li>
                  </ul>
                  <ul className="d-none d-md-block">
                    <li>User-Friendly and refined WPF-based UI/UX with multi-threaded real-time communications</li>
                    <li>VS2022, Android 12 with hardware integration (WiFi and Bluetooth)</li>
                    <li>MVVM with Xaml, Xamarin and Maui</li>
                    <li>On Google Play: <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.OtterbineSolutions.os_obd2">https://play.google.com/store/apps/details?id=com.OtterbineSolutions.os_obd2</a></li>
                  </ul>
                </li>
                <li>Maintain and update domain knowledge</li>
                <li>Maintain Angular Websites, developer accounts and application deployments</li>
                <li>Maintain Integrated ad services and linked ad accounts AdMob<sup>&copy;</sup></li>
              </ul>
            </div>
          </div>
 
        
        
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>Spectrotel, Neptune, NJ <label className="time-span">(5/21-8/21)</label></h4>
              <h5><strong>Applications Developer</strong></h5>
              <ul>
                <li>Design and develop data collection application (C#)
                  <ul>
                    <li>WPF with MVVM </li>
                    <li>ASP.NET using MVC Razor and Kendo Controls</li>
                    <li>Design new and adaptation to existing Web APIs/JSON</li>
                    <li>Entity Framework - SQL Server database modeling/design, stored procedures</li>
                    <li>Javascript, less/css, JQuery, async/single page (ajax)</li>
                    <li>Repository interfaces / DI Implementations</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        
          <div style={{pageBreakInside:'avoid',pageBreakAfter:'always'}}></div>
        
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>Verizon, Branchburg, NJ <label className="time-span">(2/19-12/20)</label></h4>
              <h5><strong>Senior Web App Developer</strong></h5>
              <ul>
                <li>Web Application UI/UX Design and implementation on small team
                  <ul>
                    <li>Microsoft Stack using Visual Studio 2019/Node/.NET Core/api interaction</li>
                    <li>ASP.NET using C# / MVC/ razor / css / bootstrap</li>
                    <li>HTML5, Javascript, JSON, less/css, JQuery, async</li>
                    <li>Team Foundation - Repositories, Planning, Task assignment, burndown</li>
                  </ul>
                </li>
                <li>Working on internal web-based software application</li>
                <li>Design and implementation of planned features in an iterative deployment environment</li>
              </ul>
            </div>
          </div>
        
        
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>D&R Lathian, Eatontown, NJ <label className="time-span">(9/16-9/18)</label></h4>
              <h5><strong>Senior Software Developer</strong></h5>
              <ul>
                <li>Detail-oriented web-related design
                  <ul>
                    <li>Microsoft Stack using Visual Studio 2015</li>
                    <li>ASP.NET using C# / MVC/razor/css/bootstrap</li>
                    <li>HTML5, Javascript, JSON, less/css, Angular/JQuery, async</li>
                    <li>Team Foundation - Repositories, Planning, Task assignment, burndown</li>
                  </ul>
                </li>
                <li>Development of proprietary web-based internal software using impersonation across IIS Servers</li>
                <li>Design and implement (SDLC from concept to working implementation), on a regular basis, customer end-user web portals applying principles of web forms, as well as integrated database functionality using Entity Framework</li>
              </ul>
            </div>
          </div>
        
        
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>DIRECT SUCCESS, Farmingdale, NJ <label className="time-span">(11/15-8/16)</label></h4>
              <h5><strong>Senior Software Developer</strong></h5>
              <ul>
                <li>Web-related design/maintenance of .ASP.NET websites
                  <ul>
                    <li>Microsoft Stack using Visual Studio 2015</li>
                    <li>Agile/Scrum with Team Foundation Services</li>
                    <li>Designed xls/csv file import via web page for internal data processing (front end and back-end parser)</li>
                    <li>NET ajax methodologies/JSON data transport</li>
                  </ul>
                </li>
                <li>Worked on the life cycle of Internal WPF application
                  <ul>
                    <li>Microsoft Stack using Visual Studio 2015</li>
                    <li>MVVM pattern</li>
                    <li>Asynchronous operations</li>
                    <li>Dependency Injection</li>
                    <li>Active Directory</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        
          <div style={{pageBreakInside:'avoid',pageBreakAfter:'always'}}></div>
        
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>MAPLE TECHNOLOGIES, Manalapan, NJ <label className="time-span">(1/15-11/15)</label></h4>
              <h5><strong>Web Applications Developer</strong></h5>
              <ul>
                <li>Web-related design based on ASP.NET in an agile/scrum environment
                  <ul>
                    <li>Visual Studio 2013</li>
                    <li>C#, WPF, MVC, Angular, HTML, css, Javascript, JSON/TFS</li>
                  </ul>
                </li>
                <li>Developed customized AJAX forms and their underlying controllers as well as server-side components</li>
                <li>Wrote customized NACHA-compliant ACH delivery system employing secure FTP and encryption</li>
                <li>Have written several sql scripts, stored procedures and tables</li>
                <li>Ported Cold Fusion to ASP.NET</li>
                <li>Test-Driven, SOLID, DI - based C# assembly design</li>
              </ul>
            </div>
          </div>
        </div>
        
        
        <div className="container" id="document-page2">
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>4D SECURITY SOLUTIONS, South Plainfield, NJ <label className="time-span">(2/08-11/14)</label></h4>
              <h5><strong>Software Developer</strong></h5>
              <ul style={{marginBottom:'0'}}>
                <li>Participate in design in agile/scrum environment
                  <ul>
                    <li>Parallel processing (multi-threaded) software package, C++,/C#, WCF, WPF</li>
                    <li>Visual Studio 2010</li>
                    <li>Team Foundation Services</li>
                  </ul>
                </li>
                <li>Full life cycle management of specialized device-specific protocol simulation application</li>
                <li>Participated in the design of customized MAK VRForces Interface module</li>
                <li>Designed device drivers for Command and Control server-side</li>
                <li>Hardware troubleshooting and interface design (ptz, sockets, Pelco,RS232,422,485, streaming, UDP/TCP)</li>
                <li>GUI design, generics/collections/serialization, threads, remoting, sockets, interfaces, reflection, SQL Server</li>
                <li>Participate in the design, development and documentation of hardware controls for security system components in a multi-threaded framework</li>
              </ul>
            </div>
          </div>
        
        
        
        
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>SHORE SOFTWARE,Point Pleasant, NJ <label className="time-span">(9/04-2/08)</label></h4>
              <h5><strong>Software Developer</strong></h5>
              <ul style={{marginBottom:'0'}}>
                <li>Retail/Custom application full life cycle design
                  <ul>
                    <li>C++ using VIsual Studio</li>
                    <li>Designed Pulsar© Cost Estimating Application</li>
                    <li>Support and deployment process management</li>
                    <li>Customers include both civilian and military government agencies as well as private businesses </li>
                  </ul>
                </li>
                <li>Developed company standards for programming practices for other developers to follow. Focused on scalability , upgrade ability and portability, this included guiding customers to use more flexible technologies</li>
                <li>.NET Encapsulation of National Instruments COM objects TestStand© / LabWindows CVI© for hardware testing applications – including GUI development in C#, custom controls</li>
                <li>Designed assembly line quality test interface application for Crestron© electronics</li>
                <li>Pocket PC / WinCE Native and .NET embedded design (C++ / C#) / ASP .NET Web Service Interface</li>
                <li>Wrote ARM processor (embedded) Medical testing firmware</li>
                <li>InstallShield / Installation design / scripting - application deployment and consumer distribution</li>
                <li>National Instruments Test Stand</li>
              </ul>
            </div>
          </div>
        
          <div style={{pageBreakInside:'avoid',pageBreakAfter:'always'}}></div>
        
        
          <div className="row justify-content-center" style={{marginTop:'1.6em'}}>
            <div className="col-12">
              <h4>North Jersey Cash Register (Hi Tech Cash Register and Computer), Newark, NJ <span className="time-span">(1996-8/04)</span></h4>
              <h5><strong>Electronics Technician/Software Developer</strong></h5>
              <ul style={{marginBottom:'0'}}>
                <li>Designed a PC-based, real-time, point of sale cafeteria cashless lunch payment system.
                  
                  <ul>
                    <li>C++ using VIsual Studio</li>
                    <li>Designed multithreaded IP server serving up to 12 Sharp© Cash Registers</li>
                    <li>Installed in many clients in NYC - NYSE, Guardian Life among others</li>
                  </ul>
                </li>
                <li>Primary role was an electronics technician servicing POS systems all around the NYC area.</li>
                <li>Repaired circuit boards</li>
              </ul>
            </div>
          </div>
        
        
        
          <div className="row justify-content-center">
            <div className="col-12">
              <h4 className="d-none d-md-block" style={{margin:'1.5em 0 1em 0'}}>Education</h4>
              <h4 className="d-block d-md-none" style={{paddingTop:'.75em',textAlign: 'center',margin:'1.5em 0 1em 0',borderTop: '1px solid grey'}}>Education</h4>
            </div>
          </div>
        
        
          <div className="row justify-content-center" style={{marginTop:'.7em'}}>
            <div className="col-12">
              <a href="https://www.devry.edu/" target='_blank'><h4 style={{display:"inline"}}>DeVry University</h4></a><h4 style={{display:"inline"}}>, New Brunswick, NJ</h4>
              <p style={{fontWeight:'400'}}>Bachelor of Applied Science, Electronics Engineering Technology</p>
            </div>
          </div>
        
          <div className="row justify-content-center" style={{marginTop:'.7em'}}>
            <div className="col-12">
              <a href="https://www.devry.edu/" target='_blank'><h4 style={{display:"inline"}}>DeVry University</h4></a><h4 style={{display:"inline"}}>, New Brunswick, NJ</h4>
              <p style={{fontWeight:'400'}}>Associate of Applied Science, Electronics Engineering Technology</p>
            </div>
          </div>
        
          <div className="row justify-content-center" style={{marginTop:'.7em'}}>
            <div className="col-12">
              <a href="https://www.shu.edu/" target='_blank'><h4 style={{display:"inline"}}>Seton Hall University</h4></a><h4 style={{display:"inline"}}>, South Orange, NJ</h4>
              <p style={{fontWeight:'400'}}>Bachelor of Arts and Sciences, Communication/Journalism</p>
            </div>
          </div>
        
        </div>
    </div>
    
    
    
    
    
    
    
    




      );
  }


  export default MOResume