//import logo from "../../public/favicon.ico";
//import logo from "%PUBLIC_URL%/favicon.ico";
import '../App.less';
import Footer from '../Components/Footer/Footer'
import { useParams } from 'react-router-dom';
var logo = "../../public/favicon.ico";
function ReactIntro() {
  const {id} = useParams();
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
           <a
            className="App-link"
            href="\"
            //target="_blank"
            rel="noopener noreferrer"
          >
            Tell your mom to learn React - {id}
          </a>
       
        </header>
      </div>
    );
  }


  export default ReactIntro